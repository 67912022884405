import {
WATCH_STOP, WATCH_START
} from "../types";

const initialState = {
  loading: false,
  timer: 0.0,
  start_timer: false
};

export default function stopWatch (state = initialState, action) {
  switch (action.type) {
    case WATCH_START:
      return {
        ...state,
        loading: false,
        start_timer: true
      }

      case WATCH_STOP: 
        return {
          ...state,
          loading: false,
          start_timer: false
        }

    default:
      return state;
  }
}
