import {
    SET_ERRORS,
    VOLUMEVALUE,
} from "../types";

import axios from "axios";
// import { io } from "socket.io-client";

export const writereload = () => dispatch => {
    let config = {
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8',
        }
      }
    axios.post('/write-reload-state',config)
    .then(res => {
        dispatch({
            type: VOLUMEVALUE,
        })
    })
    .catch(err => {
        console.log(err)
        dispatch({
            type: SET_ERRORS
        })
    });
}





export const dialPad = () => dispatch => {
    let config = {
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8',
        }
      }
    axios.get('/http://8.214.33.203:1337/web-text',config)
    .then(res => {
        console.log(res)
    })
    .catch(err => {
        console.log(err)
        dispatch({
            type: SET_ERRORS
        })
    });
}






