import React, { useState, useEffect} from 'react';
// import Login from './components/LoginPage/LoginPage'
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux'
import { logoutUser, session_state } from '../../redux/actions/userActions'

function Session(props) {
    
    const historye = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    

  
	
    return (
     <div>

     </div>
    );
}

export default Session;

