import {
    LINENAME, LINENAME_UI, DURATION, DURATION_UI, UIN_UI, BUTTON_UI, SPK_UI
} from '../types'

const initialState = {
    errors: [],
    uin_color: '',
    uin_text_color: '',
    duration_color: '',
    duration_text_color: '',
    line_text_color:'',
    spk_color:'',
    spk_background:'',
    line_color: '',
    incom_call:'',
    hold_sta : '',
    positi:'',
    buttons: []
}

export default function ui (state = initialState, action) {
    switch (action.type) {
        case UIN_UI: 
            return {
                ...state,
                uin_text_color: action.payload.uin_text_color,
                uin_color: action.payload.uin_color,
            }

        case LINENAME: 
            return {
                ...state,
                line_text: action.payload.line_text,
            }
        case LINENAME_UI: 
            return {
                ...state,
                line_text_color: action.payload.line_text_color,
                line_color: action.payload.line_color
            }

        case DURATION: 
            return {
                ...state,
                duration: action.payload.duration,
            }
        case DURATION_UI: 
            return {
                ...state,
                duration_text_color: action.payload.duration_text_color,
                duration_color: action.payload.duration_color
            }

        case SPK_UI:
            
            let dup_index1 = state.buttons.findIndex(el => Number(el.position) == action.payload.position);
            return {
                ...state,

                buttons: [
                    ...state.buttons.slice(0,dup_index1),
                    action.payload,
                    ...state.buttons.slice(dup_index1+1)
                    
                ]
            }

        case BUTTON_UI: 

            // find and slice
            let dup_index = state.buttons.findIndex(el => el.position == action.payload.position);
            let buttons_lst = {
                ...state,
                // buttons: [...state.buttons, action.payload]
                buttons: [
                    ...state.buttons.slice(0,dup_index),
                    action.payload,
                    ...state.buttons.slice(dup_index+1)
                    
                ],
                incom_call: action.state,//[ window.dis.payload.pos,action.payload.pos],//
                hold_sta : action.sta_hold,
                positih: action.payload.hold_position,
                positi: action.payload.position,
                //last_state: typeof(window.dis)!='undefined' && typeof(window.dis[action.payload.pos])!='undefined' ? {'positi':window.dis[action.payload.pos].payload.pos, 'incom_call':window.dis[action.payload.pos].state, 'hold_sta':window.dis[action.payload.pos].hold_sta, 'positih':window.dis[action.payload.pos].positih} : null
                
            }
            if (typeof(window.dis)=='undefined'){
                window.dis = {}
            }
            window.dis[action.payload.pos] = {
                payload:action.payload,
                positi: action.payload.position,
                incom_call:action.state,
                hold_sta : action.sta_hold,
                positih: action.payload.hold_position,
            }
            return buttons_lst
            

        default:
            return {
                ...state
            }
    }
}   