import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import "./Login.css";
import OTPTimer from "./OTPTimer";

// Redux;
import { connect } from 'react-redux'
import { connectToSocket } from '../../redux/actions/stationActions';
import { loginUser } from '../../redux/actions/userActions';
import { sessionUser ,sessionUsertimer, userextension} from '../../redux/actions/userActions';

// Import logo;
import enepath from '../../assets/logo/enepath.png';


class LoginPage extends Component {



  constructor (props) {
    super(props);
    this.state = {
      email:"",
      password: "",
      otp: "",
      stunServer:"",
      isChecked: false,
      loadingUser: false,
      isVisible: false,
      isVisibl: false,
      disabled1: false,
      isVisibleotp: false,
      errors: {}
    }
    window.encry = false;
    document.body.classList.remove('home-page');
    document.body.classList.add('login-page');

  }

  changeHandler = (event) => {

    if (event.target.value.length > 4 ) return false;

    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  // use to prevent the alphbet input in otp filed
  handleKeyPress(event) {
  if (!/\d/.test(event.key)) {
    event.preventDefault();
  }
  }

  handleCheckboxChange = (event) => {
    console.log(event.target.checked);
    this.setState({ isChecked: event.target.checked }); 
  };

  handleTimeOut = () => {
    console.log("OTP timer ran out");
    this.setState({ isVisible: false }); 
    this.setState({ isVisibleotp: true }); 
    this.setState({ disabled1: true }); 

  };
  
  componentDidUpdate = () => {
    setInterval(() => {
    let tab_session = sessionStorage.getItem("tab_session");
    let session_id = localStorage.getItem("session_id");
    let session_separator_usrname = localStorage.getItem("session_separator_usrname");
        if(session_separator_usrname != null && session_id != null && tab_session != null && this.props.user.authenticated && this.props.user.tabsession != tab_session){
          const body = {
            email: session_separator_usrname,
            session_id : session_id,
            tab_session: tab_session
          }
            this.props.sessionUsertimer(body);
         
        
          
        }
      }, (50000));

  }

  componentDidMount = () => {

    window.onload = (event) => {
      
    sessionStorage.setItem("tab_session", (new Date()).getTime());
    let tab_session = sessionStorage.getItem("tab_session");
    console.log("this is tab_session rand "+tab_session);
      console.log('Login page is fully loaded');
      let screen_State = localStorage.getItem("screen_State");
      console.log("this is screen_State "+screen_State);
      if(screen_State == "true" && screen_State != null){
        window.screen_lockstate.enable(); // keep the screen on!
      }
      else{
        console.log("screen wakeup look not enable");
      }
      let session_id = localStorage.getItem("session_id");
      console.log("this is session rand on load "+session_id);
      let session_separator_usrname = localStorage.getItem("session_separator_usrname");
      console.log("this is session sap on load "+session_separator_usrname);
          if(session_separator_usrname != null && session_id != null){
            const body = {
              email: session_separator_usrname,
              session_id : session_id,
              tab_session: tab_session
            }
        
            setTimeout(() => {
              this.props.sessionUser(body);
            }, 4000);
          
            
          }
    }; 
  }
  

  handleSubmit = (e) => {
    setTimeout(() => {
      if (window.encry == true) {
        this.setState({ isVisible: true }); 
        this.setState({ isVisibl: false });
        this.setState({ disabled1: false }); 

      }
    }, 5000);
    if (window.encry == false) {
      this.setState({ isVisibl: true }); 
      this.setState({ disabled1: true }); 
    }

    sessionStorage.setItem("tab_session", (new Date()).getTime());
    let tab_session = sessionStorage.getItem("tab_session");
    console.log("this is tab_session rand "+tab_session);
    localStorage.setItem("session_id", (new Date()).getTime());
    if (localStorage.getItem("otp_session_id")== null) {
      localStorage.setItem("otp_session_id", (new Date()).getTime());
    }
    let session_id = localStorage.getItem("session_id");
    let otp_session_id = localStorage.getItem("otp_session_id");
    console.log("this is session rand "+session_id);
    console.log("this is otp_session_id rand " + otp_session_id);

    localStorage.setItem("screen_State", true);
    let screen_State = localStorage.getItem("screen_State");
    console.log("this is screen_State "+screen_State);
    window.screen_lockstate.enable(); // keep the screen on!
    
    // genrate the session_separator_usrname
    localStorage.setItem("session_separator_usrname", this.state.email);
    let session_separator_usrname = localStorage.getItem("session_separator_usrname");
    console.log("this is session sap "+session_separator_usrname);
    e.preventDefault();
    
    const body = {
      email: this.state.email,
      password: this.state.password,
      session_id : session_id,
      tab_session: tab_session,
      otp: this.state.otp,
      check1: this.state.isChecked,
      otp_session_id: otp_session_id
    }

    // this.props.userextension();
    this.props.loginUser(body);

    
  }

  componentWillReceiveProps = (nextProps) => {
    let session_id = localStorage.getItem("session_id");
    let session_separator_usrname = localStorage.getItem("session_separator_usrname");
    let stunservr = localStorage.getItem("stunserver");
    this.setState({ stunServer: stunservr });
    console.log('Selected STUN Server:'+ stunservr);

    if (nextProps.user.connecting) this.setState({ loadingUser: true })
    if (nextProps.user.authenticated && nextProps.user.username == session_separator_usrname && nextProps.user.sessiion_id == session_id) { 
      this.setState({loadingUser: false})
      this.props.history.push('/main')
    }
    if (nextProps.user.errors) {
      this.setState({ errors: nextProps.user.errors, loadingUser: false })
    }

  }

  handleStunSelect = (eventKey) => {
    this.setState({ stunServer: eventKey });
    localStorage.setItem("stunserver", `${eventKey}`);
    let stunservr = localStorage.getItem("stunserver");
    console.log('Selected STUN Server:'+ stunservr);
  };

  render() {
    const { user: { connecting } } = this.props;

    return <div className="Login container">
      {/* this if is used for checking the current session */}
      {  this.props.user.logoustated ?
        <div className="text-center mt-5">
        <h3><br/><br/><br/>Session Out...<br/><br/>Please Refresh This Page</h3>
        </div>
        :
      <div className="row justify-content-center form-container my-5">
        <div className="col-md-12 col-sm-12">
          <div className="card">
            <div className="card-body account-wall">
              <img className="profile-img" src={enepath}
                    alt=""></img>
             
               {this.props.user.tabsession == sessionStorage.getItem("tab_session") ?
                 <h4 className="text-danger font-weight-bold text-center mt-4">{this.state.errors && this.state.errors.type}</h4>: ""
                }
                { !connecting ? <Form onSubmit={this.handleSubmit} className="form-signin">
                <Form.Group className="mb-4" size="lg" controlId="email">
                  <Form.Control
                    autoFocus
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.changeHandler}
                    autoComplete="off"
                    placeholder="Username"
                  /> 
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <Form.Control
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.changeHandler}
                    placeholder="Password"
                  />
                </Form.Group>
                <Form.Group size="lg" controlId="otp">
                    {this.state.isVisible &&
                      <label className="p-1">OTP has been sent to your email</label>
                    }
                    {this.state.isVisibleotp &&
                      <label className="text-center p-1">The validity of your OTP has ended. Kindly refresh the page and reattempt</label>
                    }
                    {this.state.isVisible &&
                      <Form.Control
                      name="otp"
                      type="text"
                      value={this.state.otp}
                      onKeyPress={this.handleKeyPress}
                      onChange={this.changeHandler}
                      placeholder="OTP"
                      required 
                    />}
                  </Form.Group>
                  <Form.Group size="lg" className="mt-3" controlId="timer" style={{ textAlign: "center" }}>
                    {this.state.isVisible && <OTPTimer timerDuration={240} onTimeOut={this.handleTimeOut} />}
                  </Form.Group>
                  <Form.Group size="lg" className="mt-3" controlId="remember">
                    {this.state.isVisible && <label>
                      <input
                        style={{ width: "25px", height: "25px" }}
                        type="checkbox"
                        checked={this.state.isChecked}
                        onChange={this.handleCheckboxChange}
                      />
                      &nbsp;Remember me on this device
                    </label>}
                  </Form.Group>
                <Form.Group className="mt-4 d-grid gap-2">
                    <Button block size="lg" variant="primary" type="submit" disabled={this.state.disabled1}>
                    <span className="fontlogin">Login</span>
                  </Button>
                  </Form.Group>
                  <Form.Group size="lg" className="text-center mt-3" controlId="stun">
                    <Dropdown onSelect={this.handleStunSelect}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-options">
                        {this.state.stunServer ? `Selected STUN Server: ${this.state.stunServer}` : "Select STUN Server"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="None">None</Dropdown.Item>
                        <Dropdown.Item eventKey="Google">Google</Dropdown.Item>
                        <Dropdown.Item eventKey="Twilio">Twilio</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  <Form.Group size="lg" controlId="otp" className="text-center mt-3">
                    {this.state.isVisibl &&
                      <label className="p-1">Please Wait, We're just verifying your credentials</label>
                    }
                  </Form.Group>
              </Form>: 
              <div className="text-center mt-5">
                <h3>Be Patient, Good Things Take Time<br/>Connecting...</h3>
              </div>}
            </div>
          </div>
        </div>
      </div>
  }
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    stationData: state.station,
    user: state.user
  }
}

const mapDispatchToProps = {
  sessionUsertimer, sessionUser, userextension, loginUser, connectToSocket
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);