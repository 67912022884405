//middleware/middleware.js 
import {
  SET_AUTHENTICATED,
  LOGGEDIN, LOGGEDOUT, SET_UNAUTHENTICATED,
  LOGINFAILED, REQUEST, UIN, TEXT, COLOR, LINENAME, UIN_UI, LINENAME_UI, SET_SESSION,
  } from "../types";

import * as actions from '../modules/websocket';

const socketMiddleware = () => {
  let socket = null;

  const onOpen = store => (event) => {
    console.log('websocket opend', event.target.url);
    store.dispatch(actions.wsConnected(event.target.url));
      let session_id = localStorage.getItem("session_id");
      let session_separator_usrname = localStorage.getItem("session_separator_usrname");
    if(session_separator_usrname == null &&  session_id == null){
      store.dispatch({
        type: SET_UNAUTHENTICATED
      })
    }
  };

  const onClose = store => () => {
    store.dispatch(actions.wsDisconnected()); 
  };

  const onMessage = store => (event) => {
    const payload = JSON.parse(event.data);
    console.log('receiving server message %s',payload);
    if (payload  && payload.filename) {
      let eventName = payload.filename.replace(/\s/g, '');
      const eventNameSplitter = eventName.split('%');
      switch (eventNameSplitter[2]) {
        
        case LOGGEDOUT:
            store.dispatch({
              type: SET_UNAUTHENTICATED
            })
          break;
        
        case LOGGEDIN:
          store.dispatch({
            username: eventNameSplitter[3],
            sessiion_id: eventNameSplitter[4],
            usreextension: eventNameSplitter[5],
            type: SET_AUTHENTICATED
          })
          break;
       
        case LOGINFAILED:
          store.dispatch({
            type: LOGINFAILED,
            tabsession: eventNameSplitter[3]
          })  
          break;

        case UIN: 
          if (eventNameSplitter[1] === TEXT) {
            store.dispatch({
              type: UIN,
              payload: { uin: eventNameSplitter[3] }
            })
          } else if ( eventNameSplitter[1] === COLOR ) {
            store.dispatch({
              type: UIN_UI,
              payload: { uin_color: eventNameSplitter[4], uin_text_color: eventNameSplitter[3] }
            })
          }
          
        break;

        case LINENAME: 
          if (eventNameSplitter[1] === TEXT) {
            store.dispatch({
              type: LINENAME,
              payload: { line_text: eventNameSplitter[3] }
            })
          } else if ( eventNameSplitter[1] === COLOR ) {
            store.dispatch({
              type: LINENAME_UI,
              payload: { line_color: eventNameSplitter[4], line_text_color: eventNameSplitter[3] }
            })
          }
          
        break;


        case REQUEST:
          if (eventNameSplitter[1] === LOGGEDOUT) {
            store.dispatch({
              type: SET_UNAUTHENTICATED
            })
          }
        break;

        default:
          store.dispatch({
              type: SET_AUTHENTICATED
            })
          console.log("Someone tried to add a non-validated file, it have been unlinked from station")
          break;
      }
    }
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(action.host);
        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.log('websocket closed');
        break;
      case 'WS_DISCONNECTED':
          return next(action);
          break;
      case 'NEW_MESSAGE':
        console.log('sending a message', action.msg);
        socket.send(JSON.stringify({ command: 'NEW_MESSAGE', message: action.msg }));
        break;
      case 'WS_CONNECTED':
        
        break;
      default:
        console.log('the next action:', action);
       return next(action);
    }
  };
};

export default socketMiddleware();