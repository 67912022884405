import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
// reducers
import userReducer from "./reducers/userReducer";
import stationReducer from './reducers/stationReducer';
import dialReducer from "./reducers/dialReducer";
import uiReducer from "./reducers/uiReducer";
import stopWatch from "./reducers/stopWatch";
import wsMiddleware from "./middleware/middleware";
// Middlewares
const middleware = [thunk,wsMiddleware];

const initialState = {};

const reducers = combineReducers({
  user: userReducer,
  station: stationReducer,
  dial: dialReducer,
  ui: uiReducer,
  watch: stopWatch
});

const store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(...middleware))
);

export default store;
