import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import "./wavePlayer.css";
import playButton from "../../assets/images/play-button-arrowhead.png";
import pauseButton from "../../assets/images/pause-button.png";
import forwardButton from "../../assets/images/forward.png";
import backwardButton from "../../assets/images/backward.png";
//import { secondsToHms } from "../test";

// export default function WavePlayer() {
const WavePlayer = React.forwardRef(({ ...props }, ref) => {
  const [playing, setPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState("00:00");
  const [audioCurrentTime, setAudioCurrentTime] = useState("00:00");
  const [wavesurfer, setWavesurfer] = useState({});
  useEffect(() => {
    const waveObj = WaveSurfer.create({
      container: "#waveform",
      waveColor: "gray",
      cursorWidth: 2,
      fillParent: true,
      interact: false,
      progressColor: "#2D5BFF",
      barHeight: 3,
      barRadius: 3,
      backend: "MediaElement",
      responsive: true,
      height: 70,
      normalize: false,
      hideScrollbar: true,
    });

    setWavesurfer(waveObj);

    waveObj.on("finish", function () {
      console.log("finished");
      setPlaying(false);
    });

    waveObj.on("ready", function () {
      let total_time = waveObj.getDuration();
      setAudioDuration(secondsToHms(total_time.toFixed(0)));
    });
    waveObj.on("seek", function (position) {
      setAudioCurrentTime(secondsToHms(position * waveObj.getDuration()));
    });

    waveObj.on("audioprocess", function () {
      let total_time = waveObj.getDuration();
      let current_time = waveObj.getCurrentTime();
      if (waveObj.isPlaying()) {
        setAudioDuration(secondsToHms(total_time.toFixed(0)));
        setAudioCurrentTime(secondsToHms(current_time.toFixed(0)));
      }
    });

    console.log("log from wave player " + props.url);
    if (typeof props.url !== "undefined" && props.url != "") {
      waveObj.load(props.url);
    }
  }, []);

  const handlePlay = () => {
    setPlaying(!playing);
    wavesurfer.playPause();
  };

  const handleFarward = () => {
    wavesurfer.skipForward(5);
  };
  const handleBackward = () => {
    wavesurfer.skipBackward(5);
  };

  function secondsToHms(d) {
    d = Number(d);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m : "00";
    var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
    return mDisplay + ":" + sDisplay;
  }

  const stopOnModalClose = () => {
    setPlaying(false);
    wavesurfer.stop();
  };

  useImperativeHandle(ref, () => ({
    stopOnModalClose,
  }));

  return (
    <>
      <div className="audio-player-container" id="player">
        <h5 className="py-3" style={{ fontWeight: "700 !important" }}>
          {props.channelName}- Last 20 Seconds Playback
        </h5>
        <div className="player-area">
          <div className="audio-time">
            <span id="current-time">{audioCurrentTime}</span>
          </div>
          <div className="audio-wave" id="waveform"></div>
          <div className="audio-time">
            <span id="duration">{audioDuration}</span>
          </div>
        </div>
        <div className="player-buttons-area">
          <div className="audio-player-control" onClick={handleBackward}>
            <img
              src={backwardButton}
              className="control-icon"
              width="50px"
              alt=""
            />
          </div>
          <div
            className="audio-player-control"
            onClick={handlePlay}
            style={{ width: "50px", height: "50px" }}
          >
            <img
              src={!playing ? playButton : pauseButton}
              id="play"
              className="control-icon"
              width="50px"
              alt=""
            />
          </div>
          <div className="audio-player-control" onClick={handleFarward}>
            <img
              src={forwardButton}
              className="control-icon"
              width="50px"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default WavePlayer;
