import React, {useState, useEffect, StrictMode} from 'react'
import { Row, Col, Image, Button } from 'antd';
import 'antd/dist/antd.css';
import './Header.css';
import logo from  "../../assets/logo/enepath.png"
import { useHistory } from 'react-router';

// Redux;
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser, userexten } from '../../redux/actions/userActions'

const Header = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const dialState = useSelector( state => state.dial )
    const uiState = useSelector( state => state.ui )
    const watch = useSelector( state => state.watch )
    const [timer, setTimer] = useState({
        time: '00:00', current_time: 0, stop_watch: 0
    })

    const [terminator, setTerminator] = useState()
    let timeTrick;
    

    const logOut = () => {
      let session_id = localStorage.getItem("session_id");
      let session_separator_usrname = localStorage.getItem("session_separator_usrname");
      let user_extension = localStorage.getItem("extension_num");

        const content = {
            
            extension: user_extension,
            
          }
       dispatch(userexten(content))

        const body = {
            
            email: session_separator_usrname,
            sesion_id: session_id
            
          }
        dispatch(logoutUser(history , body))

    }

    useEffect(() => {
        if (watch.start_timer) {
            setTimer({
                time: '00:00', current_time: 0, stop_watch: 0
            })
            start();
        } else if (!watch.start_timer) {
            stop();
        }        
        return () => {
            return timer
        }
        
    }, [watch])

    const start = () => {
        console.log(`start clock`)
        if(!timeTrick) {
            timeTrick = setInterval(() => {
                setTimer({ time: format(timer.current_time), current_time: timer.current_time++ }) 
                },10)
            setTerminator(timeTrick)
        }
    }
    

    
    const stop  =() => {
        //set here
        setTimer({
            time: '00:00', current_time: 0, stop_watch: 0
        })
        console.log(`timeTrick2`, terminator)
        if(terminator) {
            clearInterval(terminator);
            setTerminator(false)
        }
    }

    const format =(t) =>
    {
        let hrs
        let sec
        let min
        hrs = place(parseInt(t/(1000 * 60 * 60)) % 60)
        sec = place(parseInt(t/100)%60)
        min = place(parseInt(t/6000))
        return `${ typeof hrs != undefined &&  hrs > 0 ? hrs + ':' : ''  } ${min} : ${sec}`
    }  

    const place =(n) => 
    {
        return n < 10 ? '0' + n : n
    }
    
    return (
        <div className="main-header">
            <Row justify="center" className="title">
                <Col span={6} justify="left">
                    <Image width={70} height={70} src={logo} />
                </Col>
                <Col span={12}>{props.DisplayName} {props.UserId}</Col>
                <Col span={6}>
                <button className={`logout-btn`} onClick={logOut}>
                Logout
                </button>
                </Col>
                
            </Row>
        </div>
    )
}

export default Header