// User reducer types;
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_LOGOUTST = "SET_LOGOUTST";
export const SET_SESSION = "SET_SESSION";
export const SET_SESSION1 = "SET_SESSION1";
export const RM_USR_EXTENSION = "RM_USR_EXTENSION";
export const SESSION = "SESSION";
export const SESSION1 = "SESSION1";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const UPDATE_USER = "UPDATE_USER";

// User state
export const LOGGEDIN = "LOGGEDIN"
export const LOGGEDOUT = "LOGGEDOUT"
export const CONNECTING = "CONNECTING"
export const LOGINFAILED = "LOGINFAILED";

// Station Actions;
export const STARTUP_STATE = "STARTUP_STATE";
export const CURRENT_STATE = "CURRENT_STATE";

// Speed Dial actions;
export const END_CALL = "END_CALL";
export const START_CALL = "START_CALL";
export const HOLD_CALL = "HOLD_CALL";
export const HOLD = "HOLD";
export const INCOMINGCALL = "INCOMINGCALL";
export const INCOMING = "INCOMING";
export const SK = "SK"
export const BU = "BU"
export const PRIVATE = "PRIVATE"
export const REQUEST = "REQUEST"
export const DIALPAD = "DIALPAD"
export const VOLUMEVALUE = "VOLUMEVALUE"
export const LATCHEDOPEN = "LATCHEDOPEN"
export const SPEEDDIALED = "SPEEDDIALED"

// Errors State;
export const SET_ERRORS = "SET_ERRORS";

// Chat Action Types;
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const SENT_MESSAGE = "SENT_MESSAGE";
export const SET_CHAT_USER = "SET_CHAT_USER";

// Station types;
export const SOCKET_CONNECTION = "SOCKET_CONNECTION";

// Header actions;
export const UIN = "UIN"
export const UIN_UI = "UIN_UI"
export const LINENAME = "LINENAME"
export const LINENAME_UI = "LINENAME_UI"
export const DURATION = "DURATION"
export const DURATION_UI = "DURATION_UI"
export const TEXT = "TEXT"
export const FREE = "FREE"
export const VOLUME = "VOLUME"
export const COLOR = "COLOR"
export const BUTTON_UI = "BUTTON_UI"
export const BUTTON_TEXT = "BUTTON_TEXT";
export const SPK_TEXT = "SPK_TEXT";
export const SPK_UI = "SPK_UI";
export const SPK_STATE = "SPK_STATE";
export const SPK_VOLUME = "SPK_VOLUME";
export const VAD = "VAD";
export const SPK_VAD = "SPK_VAD";
export const CALL = "CALL"
export const START = "START"
export const END = "END"

// Watch;
export const WATCH_START = "WATCH_START"
export const WATCH_STOP  = "WATCH_STOP"