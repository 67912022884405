import React, { useState, useEffect } from "react";

function OTPTimer(props) {
    const [count, setCount] = useState(props.timerDuration);

    useEffect(() => {
        let timer = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount === 0) {
                    clearInterval(timer);
                    props.onTimeOut();
                    return prevCount;
                } else {
                    return prevCount - 1;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [props]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    };

    return <div>{formatTime(count)}</div>;
}

export default OTPTimer;
