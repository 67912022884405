import {
    START_CALL, END_CALL, HOLD_CALL, INCOMINGCALL, PRIVATE, UIN, LINENAME, DURATION, SPK_STATE, SPK_TEXT, BUTTON_TEXT, SPEEDDIALED, SPK_VOLUME, SPK_VAD, SET_LOGOUTST
} from '../types'

const initialState = {
    on_call: false,
    loading: false,
    on_hold: false,
    incoming_call: false, 
    num_info: [],
    call_num_info: [],
    private_call: false,
    errors: [],
    hold_info: '',
    uin: '',
    uin_color: '',
    uin_text_color: '',
    duration: 0.0,
    duration_color: '',
    duration_text_color: '',
    line_text: '',
    line_text_color:'',
    line_color: '',
    buttons: [],
    vad_stat:''
}

export default function dial (state = initialState, action) {
    switch (action.type) {
        case END_CALL:
             return {
                 ...state,
                on_call: false,
                loading: false,
                //on_hold: false,
                // incoming_call: false, 
                // call_num_info: '',
                private_call: false,
             }

        case HOLD_CALL:
            
            return {
                ...state,
                on_hold: true,
                num_info: action.payload.number
            }

        case START_CALL:
            return {
                ...state,
                on_call: true,
                on_hold: false,
                duration: 0.0
            };

        case SPEEDDIALED:
            let speed_dialed = [...state.call_num_info]
            let index = speed_dialed.indexOf(action.payload.position.toString())
            console.log(speed_dialed)
            console.log(index)
            if (index !== -1) speed_dialed.splice(index, 1)
            return {
                ...state,
                duration: 0.0,
                call_num_info: speed_dialed
            }

        case INCOMINGCALL:
            return {
            ...state,
            incoming_call: action.payload.state,
            call_num_info: [
                    ...state.call_num_info,
                    action.payload.number
                ]
            }

        case PRIVATE:
            return {
            ...state,
            incoming_call: action.payload.state,
            call_num_info:  [
                ...state.call_num_info,
                action.payload.number
            ],
            private_call: action.payload.state
            }

        case UIN: 
            return {
                ...state,
                uin: action.payload.uin,
            }
        case LINENAME: 
            return {
                ...state,
                line_text: action.payload.line_text,
            }

        case DURATION: 
            return {
                ...state,
                duration: action.payload.duration,
            }

        case SPK_STATE: 
            return {
                ...state,
                spk_position: action.payload.position,
                spk_state: action.payload.state,
            }

        case SPK_VAD:
            
            if (typeof(window.vadis)=='undefined'){
                window.vadis = {}
            }
            window.vadis[action.payload.pos] = {
                payload:action.payload,
                position: action.payload.position,
                state:action.payload.state,
            }

            return {
                position: action.state,
                state: action.payload.position,
                ...state,
                vad_position: action.payload.position,
                vad_state: action.payload.state,
            }

        case SPK_VOLUME:
            return {
                ...state,
                spk_position: action.payload.position,
                spk_volume: action.payload.volume,
            }
        


        case SPK_TEXT: 
            let dup_index1 = state.buttons.findIndex(el => Number(el.position) == action.payload.position);

            return {
                ...state,
                // buttons: [...state.buttons, action.payload]
                buttons: [
                    ...state.buttons.slice(0,dup_index1),
                    action.payload,
                    ...state.buttons.slice(dup_index1+1)
                ]
            }
            
        case BUTTON_TEXT: 
            let dup_index = state.buttons.findIndex(el => el.position == action.payload.position);

            return {
                ...state,
                // buttons: [...state.buttons, action.payload]
                buttons: [
                    ...state.buttons.slice(0,dup_index),
                    action.payload,
                    ...state.buttons.slice(dup_index+1)
                ]
            }

        default:
            return {
                ...state
            }
    }
}